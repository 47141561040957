import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { Layout } from '@shared/utils/layout';

import RemoveButtonGreySvg from '../images/back-button-grey.svg';
import RemoveButtonSvg from '../images/back-button.svg';

import { StyledIconButton, styles } from './back-button.styles';

export type CloseButtonProps = AppWithStyles<any> & {
  onClick: () => void;
};

export const RemoveButtonComponent: React.FC<CloseButtonProps> = (props) => {
  const { classes, onClick } = props;

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.laptop));
  const buttonSvg = isMobile ? RemoveButtonSvg : RemoveButtonGreySvg;

  return (
    <div onClick={onClick} className={classes.root}>
      <StyledIconButton {...props}>
        <img src={String(buttonSvg)} />
      </StyledIconButton>
    </div>
  );
};

export const BackButton = appWithStyles(styles)(RemoveButtonComponent);
