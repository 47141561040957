import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { Button } from '@mui/material';
import classNames from 'classnames';

import ArrowRight from './img/arrow-right.svg';
import CheckDocument from './img/check-document.svg';

import { styles } from './documents-button.styles';

export interface DocumentsButtonProps extends AppWithStyles<typeof styles> {
  onClick: () => void;
  isActive: boolean;
  label: string;
  number: number;
}

const DocumentsButtonComponent: React.FC<DocumentsButtonProps> = ({
  onClick,
  label,
  isActive,
  classes,
  number,
}) => {
  const numberComponent = isActive ? (
    <img
      alt={''}
      className={classNames(classes.checkedIcon, 'checked')}
      src={String(CheckDocument)}
    />
  ) : (
    <div className={classNames(classes.buttonNumber, 'button-number')}>{number}</div>
  );

  return (
    <Button variant="outlined" className={classes.button} disabled={isActive} onClick={onClick}>
      <div className={classes.flex}>
        {numberComponent}
        {label}
      </div>
      <img alt={''} className={classes.arrowRight} src={String(ArrowRight)} />
    </Button>
  );
};

export default appWithStyles(styles)(DocumentsButtonComponent);
