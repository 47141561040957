import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { Box, Drawer, Modal, Theme, useMediaQuery } from '@mui/material';
import { CloseDrawerButton } from '@shared/components/new-design/close-drawer-button/close-drawer-button';
import { Layout } from '@shared/utils/layout';
import classNames from 'classnames';

import { styles } from './drawer-dialog.styles';

export interface DrawerDialogProps extends AppWithStyles<typeof styles> {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  height?: number;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  background: '#291945',
  border: '1px solid #473763',
  borderRadius: '10px',
  '&:focus-visible': {
    outline: 'none',
  },
};

const DrawerDialogComponent: FC<DrawerDialogProps> = ({
  classes,
  onClose,
  isOpen,
  children,
  height,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));

  const limitComponent = <div className={classes.viewBlock}>{children}</div>;

  let content = <></>;

  if (isMobile) {
    content = (
      <Drawer
        anchor={'bottom'}
        open={isOpen}
        className={classNames(classes.drawer, `percentage-${height}`)}
        onClose={onClose}
      >
        <div className={classes.drawerContent}>{limitComponent}</div>
        <CloseDrawerButton onClick={onClose} />
      </Drawer>
    );
  } else {
    content = (
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="drawer-dialog-label"
        aria-describedby="drawer-dialog-description"
      >
        <Box sx={style}>{limitComponent}</Box>
      </Modal>
    );
  }
  return <div className={classes.root}>{content}</div>;
};

export default appWithStyles(styles)(DrawerDialogComponent);
