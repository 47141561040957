import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { styles } from './mobile-container.styles';

export interface MobileContainerProps extends AppWithStyles<typeof styles> {
  children?: React.ReactNode;
}

const MobileContainerComponent: React.FC<MobileContainerProps> = ({ classes, children }) => {
  return <div className={classes.root}>{children}</div>;
};

export default appWithStyles(styles)(MobileContainerComponent);
