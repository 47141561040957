import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import ArrowRightButtonSvg from './img/arrow-right.svg';

import { StyledIconButton, styles } from './arrow-right-button.styles';

export type ArrowRightButtonProps = AppWithStyles<any> & {
  onClick: () => void;
};

export const ArrowRightButtonComponent: React.FC<ArrowRightButtonProps> = (props) => {
  const { classes, onClick } = props;
  return (
    <div onClick={onClick} className={classes.root}>
      <StyledIconButton {...props}>
        <img src={String(ArrowRightButtonSvg)} />
      </StyledIconButton>
    </div>
  );
};

export const ArrowRightButton = appWithStyles(styles)(ArrowRightButtonComponent);
