import { appCreateStyles } from '@core/theme/utils/create-styles';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export function styles() {
  return appCreateStyles({
    active: {},
    button: {
      width: '100%',
      height: '80px',
      border: 'none',
      borderRadius: '16px',
      backgroundColor: '#373146',
      color: _var(CSS_VARIABLES.colorAdditionalWhite),
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: '160%',
      letterSpacing: '0.2px',
      textTransform: 'none',
      display: 'flex',
      justifyContent: 'space-between',

      '& + &': {
        marginTop: '12px',
      },

      '&:hover': {
        border: 'none',
        backgroundColor: '#373146',
      },
      '&.Mui-disabled': {
        //background: _var(CSS_VARIABLES.colorPrimary500),
        color: _var(CSS_VARIABLES.colorAdditionalWhite),
        border: `1px solid ${_var(CSS_VARIABLES.colorSecondary200)}`,
      },
    },
    buttonNumber: {
      borderRadius: '50%',
      width: '36px',
      height: '36px',
      backgroundColor: '#1B112D',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '12px',

      color: _var(CSS_VARIABLES.colorPrimary100),
      fontSize: '23px',
      fontWeight: 500,
      letterSpacing: '0.2px',
    },
    arrowRight: {},
    checkedIcon: {
      marginRight: '12px',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
  });
}
