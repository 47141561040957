import * as React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { SelectChangeEvent, SelectProps } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { styles, ArrowIcon } from './app-select.styles';

export type AppSelectProps = AppWithStyles<any> &
  SelectProps & {
    helperText?: string;
    instructions?: string | null | React.ReactElement;
    options: { value: string; label: string }[];
    defaultOption: string;
    resetValidation?: () => void;
    onChangeItem: (value: string | number) => void;
    size?: string;
    minWidth?: number;
  };

export const AppSelectComponent: React.FC<AppSelectProps> = ({
  classes,
  options,
  defaultOption,
  onChangeItem,
  size,
  minWidth,
}) => {
  const onChangeValue = (e: SelectChangeEvent) => {
    onChangeItem(e.target.value);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: minWidth || '100%' }} size={size || 'small'}>
      <Select
        labelId="app-select-label"
        id="app-select"
        defaultValue={defaultOption}
        label="app-select"
        IconComponent={ArrowIcon}
        onChange={onChangeValue}
      >
        {options.map((item) => (
          <MenuItem value={item.value}>{item.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const AppSelect = appWithStyles(styles)(AppSelectComponent);
