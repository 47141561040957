import { appCreateStyles } from '@core/theme/utils/create-styles';
import { IconProps } from '@mui/material';
import { withStyles } from '@mui/styles';
import { AppSvgIcon } from '@shared/components/svg-icon';

export const styles = () =>
  appCreateStyles({
    root: {},
  });

const StyledSvgIcon = withStyles(() => ({
  root: {
    width: '7px',
    position: 'absolute',
    top: '1px',
    right: '12px',
    flexShrink: 0,
  },
}))(AppSvgIcon);

export const ArrowIcon: React.FC<IconProps> = (props) => {
  return (
    <StyledSvgIcon width="6" height="5" viewBox="0 0 6 5" fill="none" className={props.className}>
      <path
        d="M3.09882 4.5C2.90637 4.83333 2.42525 4.83333 2.2328 4.5L0.0677332 0.75C-0.124717 0.416666 0.115846 -3.82375e-08 0.500746 0L4.83087 4.30172e-07C5.21577 4.6841e-07 5.45634 0.416667 5.26389 0.75L3.09882 4.5Z"
        fill="white"
      />
    </StyledSvgIcon>
  );
};
