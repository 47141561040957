import React from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AppDropdown } from '@shared/components/dropdown/dropdown';
import {
  ToggleButton,
  ToggleButtonsSmallOption,
  ToggleTypeEnum,
} from '@shared/components/new-design/toggle-button/toggle-button';

import { styles } from './chart-controls.styles';

interface Props extends AppWithStyles<typeof styles> {
  currentPeriod: string;
  periodOptions: ToggleButtonsSmallOption[];
  updatePeriod: (v: string) => void;
}

const InvestmentControlComponent: React.FC<Props> = appObserver(
  ({ classes: c, currentPeriod, periodOptions, updatePeriod }) => {
    return (
      <div className={c.container}>
        <div className={c.switchBox}>
          <div className={c.switch}>
            <ToggleButton
              options={periodOptions}
              selected={currentPeriod}
              onChange={updatePeriod}
              type={ToggleTypeEnum.PURPLE}
            />
          </div>
        </div>
        <div className={c.dropdownBox}>
          <AppDropdown
            className={c.dropdown}
            variant="outlined"
            color="secondary"
            onClick={updatePeriod}
            title={periodOptions.find((i) => i.id === currentPeriod)?.label || ''}
            options={periodOptions}
            endIcon={<KeyboardArrowDownIcon />}
          />
        </div>
      </div>
    );
  },
);

export const ChartControls = appWithStyles(styles)(InvestmentControlComponent);
