import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { DesktopContetLayout } from '@modules/new-private/layout/desktop-content-layout';

import { styles } from './desktop-container.styles';

export interface DesktopContainerProps extends AppWithStyles<typeof styles> {
  routes: Array<{ path: string; label: string; disabled?: boolean }>;
  children?: React.ReactNode;
}

const DesktopContainerComponent: FC<DesktopContainerProps> = ({ classes, routes, children }) => {
  return (
    <DesktopContetLayout links={routes}>
      <div className={classes.content}>{children}</div>
    </DesktopContetLayout>
  );
};
export default appWithStyles(styles)(DesktopContainerComponent);
