import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      height: '0px',
    },
    viewBlock: {
      padding: '20px 20px 20px 20px',
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      minHeight: '320px',
    },
    drawer: {
      '& .MuiPaper-root': {
        maxHeight: '50%',
        height: 'auto',
      },
      '&.percentage-90 .MuiPaper-root': {
        maxHeight: '90%',
        height: 'auto',
      },
    },
    drawerContent: {
      maxHeight: '100%',
      overflow: 'auto',
    },
  });
}
