import * as React from 'react';
import { useState } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { SelectChangeEvent, SelectProps } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import classNames from 'classnames';

import { styles, ArrowIcon } from './app-select-date.styles';

export type AppSelectProps = AppWithStyles<any> &
  SelectProps & {
    helperText?: string;
    instructions?: string | null | React.ReactElement;
    options: { value: string; label: string }[];
    defaultOption: string;
    resetValidation?: () => void;
    onChangeItem: (value: string | number) => void;
    isTop?: boolean;
    label: string;
  };

export const AppSelectDateComponent: React.FC<AppSelectProps> = ({
  classes,
  options,
  defaultOption,
  onChangeItem,
  label,
  isTop,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(defaultOption);

  const onChangeValue = (e: SelectChangeEvent) => {
    onChangeItem(e.target.value);
    setSelectedValue(e.target.value);
  };

  return (
    <FormControl
      className={classNames(
        classes.root,
        { [classes.up as string]: isTop },
        { [classes.bottom as string]: !isTop },
      )}
    >
      <div className={classes.label}>{label}</div>
      {selectedValue ? <></> : <div className={classes.placeholder}>{t`Select`}</div>}
      <Select
        labelId="app-select-group-label-1"
        id="app-select-group-1"
        defaultValue={defaultOption}
        label="app-select"
        IconComponent={ArrowIcon}
        onChange={onChangeValue}
      >
        {options.map((item) => (
          <MenuItem value={item.value}>{item.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const AppSelectDate = appWithStyles(styles)(AppSelectDateComponent);
