import { appCreateStyles } from '@core/theme/utils/create-styles';
import AppSvgIcon from '@shared/components/new-design/images/close-drawer.svg';

export const styles = () =>
  appCreateStyles({
    root: {
      position: 'absolute',
      backgroundImage: `url(${String(AppSvgIcon)})`,
      backgroundRepeat: 'no-repeat',
      width: '32px',
      height: '32px',
      cursor: 'pointer',
      top: '16px',
      right: '16px',
    },
  });
