import { appCreateStyles } from '@core/theme/utils/create-styles';
import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import { AppSvgIcon } from '@shared/components/svg-icon';

export const styles = () =>
  appCreateStyles({
    root: {},
  });

export const StyledIconButton = withStyles(() => ({
  root: {
    width: '25px',
    height: '25px',
  },
}))(IconButton);

export const StyledSvgIcon = withStyles(() => ({
  root: {
    width: '24px',
    height: '24px',
  },
}))(AppSvgIcon);
