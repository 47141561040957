import { AppTheme } from '@core/theme/types/main';
import { appCreateStyles } from '@core/theme/utils/create-styles';
import { IconProps } from '@mui/material';
import { withStyles } from '@mui/styles';
import { AppSvgIcon } from '@shared/components/svg-icon';
import { Layout } from '@shared/utils/layout';

export const styles = ({ breakpoints }: AppTheme) =>
  appCreateStyles({
    root: {
      height: '48px',
      width: '100%',
      background: '#1E1D36',
      display: 'flex',
      flexDirection: 'row',
      padding: '0px 18px',
      [breakpoints.up(Layout.laptop)]: {
        background: '#412D68',
      },
      '& .MuiInputBase-root': {
        background: 'transparent',
        color: '#FFF',
        textAlign: 'right',
        whitespace: 'nowrap',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0.3px',
        height: '100%',
        paddingRight: '42px',
      },
      '& .MuiSelect-select': {
        padding: '0px !important',
      },
    },
    up: {
      borderRadius: '12px 12px 0px 0px',
    },
    bottom: {
      borderTop: '1px solid rgba(255,255,255, 0.1)',
      borderRadius: '0px 0px 12px 12px',
    },
    placeholder: {
      color: '#FFF',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '0.3px',
      position: 'absolute',
      right: '70px',
      top: '13px',
    },
    label: {
      color: '#7B7C9E',
      whitespace: 'nowrap',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '0.3px',
      verticalAlign: 'center',
      display: 'flex',
      alignItems: 'center',
    },
  });

const StyledSvgIcon = withStyles(() => ({
  root: {
    width: '26px',
    height: '26px',
    flexShrink: 0,
    posion: 'absolute',
    top: '11px',
    right: '0px',
  },
}))(AppSvgIcon);

export const ArrowIcon: React.FC<IconProps> = (props) => {
  return (
    <StyledSvgIcon
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <rect width="26" height="26" rx="13" fill="#444676" />
      <path
        d="M10.8205 10.1021V7.8457M15.3333 10.1021V7.8457M10.2564 12.3585H15.8974M9.12821 17.9995H17.0256C17.3249 17.9995 17.6118 17.8807 17.8234 17.6691C18.035 17.4575 18.1538 17.1706 18.1538 16.8713V10.1021C18.1538 9.80289 18.035 9.51593 17.8234 9.30435C17.6118 9.09277 17.3249 8.97391 17.0256 8.97391H9.12821C8.82899 8.97391 8.54202 9.09277 8.33044 9.30435C8.11886 9.51593 8 9.80289 8 10.1021V16.8713C8 17.1706 8.11886 17.4575 8.33044 17.6691C8.54202 17.8807 8.82899 17.9995 9.12821 17.9995Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </StyledSvgIcon>
  );
};
