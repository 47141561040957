import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import DeleteButtonSvg from './img/delete-button.svg';

import { StyledIconButton, styles } from './delete-button.styles';

export type ArrowRightButtonProps = AppWithStyles<any> & {
  onClick: () => void;
};

export const DeleteButtonComponent: React.FC<ArrowRightButtonProps> = (props) => {
  const { classes, onClick } = props;
  return (
    <div onClick={onClick} className={classes.root}>
      <StyledIconButton {...props}>
        <img src={String(DeleteButtonSvg)} />
      </StyledIconButton>
    </div>
  );
};

export const DeleteButton = appWithStyles(styles)(DeleteButtonComponent);
